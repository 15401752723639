<template>
  <MainHeaderCreator>
    <div>
      <el-button type="primary" @click="submitForm(ruleFormRef)">
        {{ $t("button.publish") }}
      </el-button>
      <el-button type="primary" @click="openAuthorCenter">
        {{ $t("button.articleManagement") }}
      </el-button>
    </div>
  </MainHeaderCreator>
  <div class="outer-box">
    <div class="top-box">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        size="large"
        status-icon
      >
        <el-form-item :label="$t('label.title')" prop="title">
          <el-input
            v-model="ruleForm.title"
            :placeholder="$t('publishedPage.inputTitle')"
          />
        </el-form-item>
        <el-form-item :label="$t('label.type')" prop="type">
          <ArticleTypeSel v-model="ruleForm.type" />
        </el-form-item>
        <el-form-item :label="$t('label.summary')" prop="summary">
          <el-input
            v-model="ruleForm.summary"
            :placeholder="$t('publishedPage.subTitle')"
          />
        </el-form-item>
        <el-form-item :label="$t('label.coverPicture')">
          <div v-if="imageUrl" class="avatar-wrap">
            <el-button circle @click="handleDele"
              ><el-icon :size="16"> <Delete /> </el-icon
            ></el-button>
            <img :src="imageUrl" class="avatar" />
          </div>
          <el-upload
            v-else
            ref="uploadRef"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleFileChange"
            accept=".jpg,.png,.jpeg"
          >
            <div class="upload-icon">
              <el-icon :size="22" color="#a3a3a3"><Plus /></el-icon>
            </div>
          </el-upload>
        </el-form-item>
        <div class="editor-box">
          <CreateEditor v-model="ruleForm.content" />
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import MainHeaderCreator from "@/components/MainHeaderCreator.vue";
import CreateEditor from "@/components/author/components/CreateEditor";
import ArticleTypeSel from "@/components/author/components/ArticleTypeSel";
import { onMounted, reactive, ref } from "vue";
import {
  createArticle,
  getArticle,
  editArticle,
  uploadImage,
} from "@/api/list/article.js";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { isLogin, extractFirstImageSrc } from "@/utils/index.js";
import { loginDialog } from "@/utils/dialog.js";
import xss from "xss";

const { commit, dispatch } = useStore();
const router = useRouter();
const route = useRoute();
const imageUrl = ref();
const ruleForm = ref({
  title: "",
  type: "",
});
const rules = reactive({
  title: [{ required: true, message: "请输入标题", trigger: "blur" }],
  type: [{ required: true, message: "请选择类型", trigger: "blur" }],
  summary: [
    { required: true, message: "请输入摘要", trigger: "blur" },
    { min: 10, message: "摘要需满足最少10个字", trigger: "blur" },
  ],
});
const ruleFormRef = ref();
const sendData = () => {
  commit("moduleArticle/SetPublishedTitle", ruleForm.value.title);
  const reqFun = ruleForm.value.id ? editArticle : createArticle;
  let content = xss.filterXSS(ruleForm.value.content);
  let headImgUrl = extractFirstImageSrc(content) || "";
  if (imageUrl.value) {
    headImgUrl = imageUrl.value;
  }
  reqFun({
    ...ruleForm.value,
    content,
    headImgUrl,
  })
    .then(() => {
      router.push({
        name: "PublishedPage",
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
const submitForm = (formEl) => {
  if (!isLogin()) {
    loginDialog();
    return;
  }
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      if (ruleForm.value.content.length == 0) {
        ElMessage({
          type: "warning",
          message: "请输入文章内容",
        });
        return;
      }
      sendData();
    } else {
      console.log("error submit!");
      return false;
    }
  });
};
const getDetail = (id) => {
  getArticle({ id })
    .then(({ data }) => {
      ruleForm.value = {
        ...ruleForm.value,
        ...(data || {}),
      };
    })
    .catch((e) => {
      console.log(e);
    });
};
const initData = () => {
  const { id } = route.query;
  dispatch("moduleArticle/getArtcileOptions");
  if (!id) return;
  getDetail(id);
};
// 去文章管理菜单
const openAuthorCenter = () => {
  router.push({
    name: "ArticleManage",
  });
};

const handleDele = () => {
  imageUrl.value = "";
};

const uploadFile = (file) => {
  let param = new FormData();
  param.append("file", file.raw);
  uploadImage(param)
    .then((data) => {
      if (data.message === "ok") {
        let url =
          window.location.protocol +
          "//m.one.mi.com/image" +
          data.data.substr(data.data.lastIndexOf("/"));
        imageUrl.value = url;
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch(() => {});
};

const handleFileChange = (_, fileList) => {
  const fileToUpload = fileList[0];
  if (fileToUpload) {
    uploadFile(fileToUpload);
  }
};
onMounted(() => {
  initData();
});
</script>

<style lang="scss" scoped>
.outer-box {
  height: calc(100vh - 100px);
  padding: 24px 0;
  overflow: auto;
}
.top-box {
  background: #fff;
  width: 1200px;
  margin: 0 auto;
}
.demo-ruleForm {
  padding: 20px;
}
.editor-box {
  border: solid 1px #ccc;
}
.upload-icon {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.avatar-wrap {
  position: relative;
}
.avatar-wrap:hover .el-button {
  opacity: 0.7;
}
.avatar-wrap .el-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 24px;
  height: 24px;
  margin: -20px 0 0 -12px;
  opacity: 0;
}
.avatar {
  width: 96px;
  height: 96px;
}
</style>
