import { createApp } from "vue";
import i18n from "./locales/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./style/reset.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.config.globalProperties.$appName = process.env.VUE_APP_APPNAME;
app.use(i18n).use(store).use(router).use(ElementPlus).mount("#app");
