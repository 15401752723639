<template>
  <el-select v-model="selected" placeholder="请选择" class="type-sel">
    <el-option
      v-for="item in options"
      :key="item.code"
      :label="item.chineseDesc"
      :value="item.code"
    />
  </el-select>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
const { state } = useStore();
const props = defineProps({
  modelValue: {},
});
const options = computed(() => state.moduleArticle.articleOptions);
const emits = defineEmits(["update:modelValue"]);
const selected = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emits("update:modelValue", v);
  },
});
</script>

<style lang="scss" scoped>
.type-sel {
  width: 100%;
}
</style>
