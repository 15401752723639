<template>
  <MainHeaderCreator>
    <el-button type="primary" @click="toIndex">{{ t("home.title") }}</el-button>
  </MainHeaderCreator>
  <div class="author-page">
    <div class="left">
      <p class="top-box">
        <ToCreateBtn class="top-btn" />
      </p>
      <AuthorMenu />
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import AuthorMenu from "@/components/author/AuthorMenu.vue";
import MainHeaderCreator from "@/components/MainHeaderCreator";
import ToCreateBtn from "@/components/ToCreateBtn";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const toIndex = () => {
  router.push({
    name: "HomePage",
  });
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  line-height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
}

.left,
.right {
  display: inline-block;
  vertical-align: top;
}
.author-page {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px 0;
  text-align: center;
  position: relative;
}
.left {
  background: #fff;
  overflow: auto;
  position: fixed;
  width: 160px;
  top: 80px;
  left: 40px;
  bottom: 40px;
}
.right {
  margin-left: 20px;
  width: 1020px;
}
.top-box {
  padding: 10px 10px 5px 10px;
}
.top-btn {
  width: 100%;
}
.title {
  margin-left: 6px;
  font-size: 22px;
}
</style>
