<template>
  <el-dialog
    v-model="state.show"
    :title="props.type == 1 ? '修改用户' : '修改密码'"
    width="540"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
    :show-close="false"
    class="edit-user"
  >
    <el-form :model="state.form" :rules="rules" ref="formRef">
      <template v-if="props.type == 1">
        <el-row :gutter="0">
          <el-col :span="24">
            <el-form-item prop="nickname" label="昵称:">
              <el-input
                v-model="state.form.nickname"
                placeholder="请输入昵称"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="24">
            <el-form-item prop="avatar" label="头像:">
              <el-radio-group v-model="state.form.avatar">
                <el-radio
                  size="large"
                  border
                  v-for="(item, i) in state.imgResult"
                  :key="i"
                  class="image-item"
                  :label="i"
                >
                  <template #default>
                    <el-image :src="`/img/${i}.jpeg`" />
                  </template>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-row :gutter="0">
          <el-col :span="24">
            <el-form-item prop="oldPassword" label="旧密码:">
              <el-input
                v-model="state.form.oldPassword"
                type="password"
                placeholder="请输入旧密码"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="24">
            <el-form-item prop="newPassword" label="新密码:">
              <el-input
                v-model="state.form.newPassword"
                type="password"
                placeholder="请输入新密码"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="emits('onCancel')">取消</el-button>
        <el-button type="primary" @click="onSubmit"> 提交 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  reactive,
  defineProps,
  watch,
  defineEmits,
  onBeforeMount,
  ref,
} from "vue";
import store from "@/store/index";
import { editAvatar, editPassword, logout } from "@/api/user/index";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { generateToken } from "@/utils/jwt";

const router = useRouter();
const formRef = ref();

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  type: {
    type: Number,
    default: 1,
  },
});

const emits = defineEmits(["onCancel"]);
const state = reactive({
  show: false,
  imgResult: [],
  ranNum: 23,
  form: {
    nickname: undefined,
    oldPassword: undefined,
    newPassword: undefined,
    avatar: undefined,
    email: store.getters.userInfo.email,
  },
});

const validatePass = (_, value, callback) => {
  if (value === "") {
    callback(new Error("请输入密码"));
  } else if (value.length < 6) {
    callback("密码长度不能小于6位字符");
  } else if (value.length > 16) {
    callback("密码长度不能超过16位字符");
  } else {
    callback();
  }
};

const rules = {
  nickname: [
    { required: true, message: "请输入昵称", trigger: ["blur", "change"] },
  ],
  oldPassword: [
    { required: true, message: "请输入旧密码", trigger: ["blur", "change"] },
    { validator: validatePass, trigger: "blur" },
  ],
  newPassword: [
    { required: true, message: "请输入新密码", trigger: ["blur", "change"] },
  ],
  avatar: [
    { required: true, message: "请选择头像", trigger: ["blur", "change"] },
  ],
};

onBeforeMount(() => {
  let arr = [];
  let result = [];

  for (let j = 0; j < state.ranNum; j++) {
    arr.push(j);
  }

  for (let i = 0; i < state.ranNum; i++) {
    result.push(i);
  }
  state.imgResult = result.map((index) =>
    require(`../../../public/img/${index}.jpeg`)
  );
});

watch(
  () => props.visible,
  (val) => {
    if (!val) {
      state.form = {
        nickname: undefined,
        oldPassword: undefined,
        newPassword: undefined,
        avatar: undefined,
        email: store.getters.userInfo.email,
      };
    } else if (props.type == 1) {
      state.form = {
        nickname: store.getters.userInfo.nickname,
        oldPassword: undefined,
        newPassword: undefined,
        avatar: store.getters.userInfo.avatar,
        email: store.getters.userInfo.email,
      };
    }
    state.show = val;
  }
);

const logoutFn = () => {
  logout()
    .then((data) => {
      if (data.code === 0) {
        router.push({
          path: "/login",
        });
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

const onSubmit = () => {
  formRef.value.validate((bool) => {
    if (bool) {
      let fn = props.type == 1 ? editAvatar : editPassword;
      if (props.type == 1) {
        state.form.avatar = `/img/${state.form.avatar}.jpeg`;
      }
      let obj = {
        ...state.form,
      };
      if (props.type != 1) {
        obj.oldPassword = generateToken(obj.oldPassword);
        obj.newPassword = generateToken(obj.newPassword);
      }
      fn(obj)
        .then((data) => {
          if (data.data) {
            ElMessage.success("编辑成功");
            emits("onCancel");
            logoutFn();
          } else {
            ElMessage.error(data.message || "编辑失败");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });
};
</script>

<style lang="scss">
.edit-user {
  .el-dialog__header {
    text-align: left;
  }
  .el-dialog__body {
    padding: 12px 20px;
  }
}
.el-radio.is-bordered.el-radio--large.image-item {
  display: inline-block;
  padding: 0;
  border-radius: 0;
  border: 2px solid #fff;
  margin: 0 12px 12px 0;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  &.is-checked {
    border-color: #409eff;
  }
  .el-radio__input {
    display: none;
  }
  .el-radio__inner {
    &::after {
      content: none;
    }
  }
  .el-radio__label {
    display: inline-block;
    padding: 0;
    .el-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>
