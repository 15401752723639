<template>
  <div class="user-info">
    <el-image
      style="width: 40px; height: 40px; border-radius: 50%; margin-right: 4px"
      :src="
        store.getters.userInfo.avatar ||
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      "
      fit="cover"
    />
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        {{ store.getters.userInfo.nickname || $t("header.defaultNickname") }}
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu v-show="store.getters.userInfo.nickname">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu v-show="!store.getters.userInfo.nickname">
          <el-dropdown-item command="login">登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { logout } from "@/api/user/index.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import store from "@/store/index";
import { onMounted } from "vue";
import { useStore } from "vuex";
const { dispatch } = useStore();
import { isLogin } from "@/utils/index.js";

const router = useRouter();
const logoutFn = () => {
  logout()
    .then((data) => {
      if (data.code === 0) {
        router.push({
          path: "/login",
        });
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

const handleCommand = (command) => {
  switch (command) {
    case "logout":
      logoutFn();
      break;
    case "login":
      router.push({
        path: "/login",
      });
      break;
    default:
      break;
  }
};

onMounted(() => {
  if (isLogin()) {
    dispatch("moduleUser/getInfo");
  }
});
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 12px;
  .el-dropdown-link {
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }
  .el-dropdown {
    &:focus-visible {
      outline: none;
    }
  }
}
</style>
