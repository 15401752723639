import JSEncrypt from "jsencrypt";

// 将公钥字符串转换为JSEncrypt可以使用的格式
const publicKeyPEM = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAisfs5j1SuxyhfTDedSBgpCFA8SE
dCRI7Ir7c8WLfKkSLKeTlMAYXqcd4580wMM/wfAC3xWea/4wvYAPHSdscgJitnnRXtp8pBQ
+eabPidRVy2tCX79h18WQbiIID2G21L0jqXrEiu2PBmCb9PzQUuaaaYMmjAdfTAfUn0wVSzbb
kM9r4NXeSPBbC12qew3XdFcb4DL0IG+4InWXO1cwzm65DQFuNQ4gT6ZJ17GsbndtetJD8ZY9f
Oe1iEN1JlkQgurQCvgNbqrxtsfpzKfAlOQNUorZapg3EDihXs0ir/XjEg9hmmoN9LiOxU4Jad
8ycCpekHwPKbX/jApRrnTYx+wIDAQAB
-----END PUBLIC KEY-----`;

export const generateToken = (str) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKeyPEM);
  return encryptor.encrypt(str);
};
