<template>
  <div class="msg-content">
    <el-image
      style="width: 30px; height: 30px; border-radius: 50%; margin-right: 4px"
      :src="
        props.item.avatar ||
        loadImgFn(props.item.userId) ||
        props.imgObj[props.item.userId]
      "
      fit="cover"
    />
    <div class="content-wrap">
      <p class="name">{{ props.item.nickname }}</p>
      <p class="content">{{ props.item.message }}</p>
      <Operate
        :parentId="item.id"
        :layer="props.layer + 1"
        :item="item"
        @getList="getList"
      />
      <template v-if="props.item.list?.length">
        <Message
          v-for="chil in props.item.list"
          :key="chil.id"
          :parentId="chil.id"
          :layer="props.layer + 1"
          :item="chil"
          @getList="getList"
          @loadImg="loadImgFn"
          :imgObj="props.imgObj"
        />
      </template>
    </div>
  </div>
</template>
<script setup>
import Operate from "./Operate.vue";
import Message from "./Message.vue";
import { defineProps, defineEmits } from "vue";
const events = defineEmits(["getList", "loadImg"]);
const props = defineProps({
  parentId: {
    type: Number,
    default: 0,
  },
  layer: {
    type: Number,
    default: 0,
  },
  item: {
    type: Object,
    default() {
      return {};
    },
  },
  imgObj: {
    type: Object,
    default() {
      return {};
    },
  },
});
const getList = () => {
  events("getList");
};

const loadImgFn = (id) => {
  events("loadImg", id);
};
</script>

<style lang="scss" scoped>
.msg-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 12px;
  &:deep(.el-image) {
    margin-right: 16px !important;
  }
  .content-wrap {
    flex: 1;
  }
  .name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
    margin: 0 0 8px;
    color: #5d5b5b;
  }
  .content {
    padding: 0;
    margin: 0 0 8px;
    color: #333;
    word-break: break-all;
  }
}
</style>
