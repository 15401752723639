import { getUserInfo } from "@/api/user/index.js";
import Cookie from "@/utils/cookie";
export default {
  namespaced: true,
  state: {
    userInfo: {},
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    getInfo({ commit }) {
      getUserInfo({}).then(({ data }) => {
        if (data) {
          commit("setUserInfo", data);
        } else {
          Cookie.del("serviceToken");
        }
      });
    },
  },
};
