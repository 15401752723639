import Cookie from "@/utils/cookie";
const regex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isLogin = () => {
  return !!Cookie.get("serviceToken");
};

export const validEmail = (email) => {
  return regex.test(email);
};

export const extractFirstImageSrc = (htmlString) => {
  if (!htmlString || htmlString.length < 10) {
    return null;
  }
  const regex = /<img[^>]+src="([^"]+)"/;
  const match = htmlString.match(regex);
  return match?.[1] || null;
};

export const checkPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[+!@#$%^&*(),.?":{}|<>]).{8,16}$/;
