<template>
  <el-button type="primary" @click="toCreate">
    {{ $t("header.createArticle") }}
  </el-button>
</template>

<script setup>
import { useRouter } from "vue-router";
import { isLogin } from "@/utils/index.js";
import { loginDialog } from "@/utils/dialog.js";
const router = useRouter();
const toCreate = () => {
  if (isLogin()) {
    router.push({
      name: "AuthorCreate",
    });
  } else {
    loginDialog();
  }
};
</script>

<style lang="scss" scoped></style>
