<template>
  <div class="main-box">
    <div class="page-box">
      <div class="base-info">
        <h1 class="title">{{ ruleForm.title }}</h1>
        <p class="detail">
          <span class="detail-item"
            >{{ ruleForm.author || "类型" }}
            <i class="my-i"></i>
          </span>
          <span class="detail-item">
            <ArtcleType :type="ruleForm.type" />
          </span>
        </p>
      </div>
      <div class="info-box">
        <CreateEditor
          height="200px"
          v-model="ruleForm.content"
          :showToolbar="false"
          :editorConfig="editorConfig"
        />
      </div>
    </div>
    <MsgList :id="route.params.id" />
  </div>
</template>

<script setup>
import CreateEditor from "@/components/author/components/CreateEditor.vue";
import { onMounted, ref } from "vue";
import { getArticle } from "@/api/list/article.js";
import { useRoute } from "vue-router";
import ArtcleType from "@/components/ArtcleType";
import { useStore } from "vuex";
import MsgList from "@/components/MsgList/index.vue";

const { dispatch } = useStore();
const route = useRoute();
const editorConfig = {
  placeholder: "",
  readOnly: true,
  scroll: true,
};
const ruleForm = ref({
  title: "",
  type: "",
});

const getDetail = (id) => {
  getArticle({ id }).then(({ data }) => {
    ruleForm.value = {
      ...ruleForm.value,
      ...(data || {}),
    };
  });
};
const initData = () => {
  const { id } = route.params;
  if (!id) return;
  dispatch("moduleArticle/getArtcileOptions");
  getDetail(id);
};
onMounted(() => {
  initData();
});
</script>

<style lang="scss" scoped>
.page-box {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 20px 0;
  .base-info {
    padding: 0 40px;
  }
  .info-box {
    padding: 0 30px;
  }
}
.title {
  margin-bottom: 5px;
}
.article-title {
  margin: 0 0 1.3rem;
  font-size: 2.667rem;
  font-weight: 600;
  line-height: 1.31;
  color: #252933;
}
.detail {
  margin-bottom: 10px;
}
.detail-item {
  margin-right: 5px;
  color: #8a919f;
  font-size: 14px;
}
.main-box {
  width: 100%;
  overflow: auto;
  padding: 20px 0 52px;
}
</style>
