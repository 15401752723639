import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import MainHeader from "@/components/MainHeader";
import AuthorCenter from "@/components/author/AuthorCenter";
import LayoutAuthor from "@/layout/LayoutAuthor";
import AuthorCreate from "@/views/AuthorCreate.vue";
import PublishedView from "@/views/PublishedView.vue";
import ArticleManage from "@/components/author/ArticleManage";
import ArticleDetail from "@/views/ArticleDetail.vue";
import Cookie from "@/utils/cookie";

export const checkLogin = () => {
  if (!Cookie.get("serviceToken")) {
    window.location.href = window.location.origin + "/login";
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: MainHeader,
    redirect: "/index",
    children: [
      {
        path: "index",
        component: HomeView,
        name: "HomePage",
      },
      {
        path: "published",
        beforeEnter: checkLogin,
        component: PublishedView,
        name: "PublishedPage",
      },
      {
        path: "article/:id",
        component: ArticleDetail,
        name: "ArticleDetailPage",
      },
    ],
  },
  {
    path: "/author",
    name: "author",
    component: LayoutAuthor,
    children: [
      {
        path: "index",
        beforeEnter: checkLogin,
        component: AuthorCenter,
        name: "AuthorCenter",
      },
      {
        path: "article-manage",
        component: ArticleManage,
        name: "ArticleManage",
      },
    ],
  },
  // {
  //   path: "/file-list",
  //   name: "files",
  //   component: () =>
  //     import(/* webpackChunkName: "files" */ "../views/FileList.vue"),
  // },
  {
    path: "/author-create", // 写文章
    name: "AuthorCreate",
    beforeEnter: checkLogin,
    component: AuthorCreate,
  },
  {
    path: "/about",
    name: "about",
    // beforeEnter: checkLogin,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register/index.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/ResetPassword/index.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
