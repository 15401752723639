<template>
  <div class="msg-list">
    <h4>留言</h4>
    <template v-if="state.msgList?.length">
      <div class="msg-content first" v-for="v in state.msgList" :key="v.id">
        <el-image
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 4px;
          "
          :src="v.avatar || loadImg(v.userId)"
          fit="cover"
        />
        <div class="contetn-wrap">
          <p class="name">{{ v.nickname }}</p>
          <p class="content">{{ v.message }}</p>
          <Operate
            :parentId="v.id"
            :layer="2"
            :item="v"
            @getList="getMsg(props.id)"
          />
          <template v-if="v.list?.length">
            <Message
              v-for="chil in v.list"
              :key="chil.id"
              :parentId="v.id"
              :layer="2"
              :item="chil"
              @getList="getMsg(props.id)"
              @loadImg="loadImg"
              :imgObj="state.imgObj"
            />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <el-empty description="暂无留言，快去留言吧～" :image-size="100" />
    </template>
    <div class="input-msg">
      <template v-if="isLogin()">
        <el-input
          v-model.trim="state.msg"
          :rows="3"
          type="textarea"
          placeholder="请输入留言"
          :maxlength="300"
          show-word-limit
          style="width: 100%"
        />
        <div class="btns">
          <el-button
            :disabled="!state.msg"
            size="small"
            type="primary"
            @click="handleOk"
            >确定</el-button
          >
        </div>
      </template>
      <el-alert v-else type="warning" show-icon :closable="false">
        <p class="tips">
          <span>您还未登录，请先</span
          ><el-button class="login-btn" @click="handleLogin" text type="primary"
            >登录</el-button
          >后发表留言。
        </p>
      </el-alert>
    </div>
  </div>
</template>
<script setup>
import Operate from "./Operate.vue";
import Message from "./Message.vue";
import { defineProps, reactive, onMounted, onBeforeMount } from "vue";
import { getMessagesByArticleId, addMessage } from "@/api/message/index";
import { ElMessage } from "element-plus";
import { isLogin } from "@/utils/index.js";
import { loginDialog } from "@/utils/dialog.js";

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const state = reactive({
  msgList: [],
  msg: undefined,
  ranNum: 23,
  imgResult: [],
  imgObj: {},
  curentIndex: 0,
});

onBeforeMount(() => {
  let arr = [];
  let result = [];

  for (let j = 0; j < state.ranNum; j++) {
    arr.push(j);
  }

  for (let i = 0; i < state.ranNum; i++) {
    let ran = Math.floor(Math.random() * (arr.length - i));
    result.push(arr[ran]);
    arr[ran] = arr[arr.length - i - 1];
  }
  state.imgResult = result.map((index) => require(`./img/${index}.jpeg`));
});

const loadImg = (opUsername) => {
  if (state.imgObj[opUsername]) {
    return state.imgObj[opUsername];
  } else {
    let img = state.imgResult[state.curentIndex % state.ranNum];
    state.curentIndex += 1;
    state.imgObj[opUsername] = img;
    return img;
  }
};

const handleLogin = () => {
  window.location.href = window.location.origin + "/login";
};

const getMsg = (articleId) => {
  getMessagesByArticleId({ articleId }).then(({ data }) => {
    state.msgList = data || [];
  });
};
const handleOk = () => {
  if (!isLogin()) {
    loginDialog();
    return;
  }
  addMessage({
    articleId: props.id,
    parentId: undefined,
    layer: 1,
    message: state.msg,
  })
    .then((data) => {
      if (data.message == "ok") {
        ElMessage.success("留言成功！");
        state.msg = undefined;
        getMsg(props.id);
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

onMounted(() => {
  getMsg(props.id);
});
</script>
<style lang="scss" scoped>
.msg-list {
  width: 1200px;
  margin: 20px auto 0;
  background: #fff;
  padding: 16px 20px;
  border-radius: 5px;
  h4 {
    color: #3a3939;
    margin-bottom: 8px;
  }
  .msg-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 12px;
    &.first {
      margin-top: 0;
      margin-bottom: 16px;
    }
    &:deep(.el-image) {
      margin-right: 16px !important;
    }
    .contetn-wrap {
      flex: 1;
    }
    .name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0;
      margin: 0 0 8px;
      color: #5d5b5b;
    }
    .content {
      padding: 0;
      margin: 0 0 8px;
      color: #333;
      word-break: break-all;
    }
  }
  .input-msg {
    margin: 0 0 12px 52px;
    .btns {
      margin-top: 12px;
      text-align: right;
    }
    .tips {
      display: flex;
      align-items: center;
    }
    .login-btn {
      margin: 0;
      padding: 0;
      font-size: inherit;
    }
  }
}
</style>
