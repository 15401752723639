export default {
  header: {
    defaultNickname: "Guest",
    homeTitle: "Home",
    articleManagement: "Management",
    switchLanguage: "Switch Language",
    createArticle: "Write Article",
    authorCenter: "Author Center",
  },
  login: {
    title: "Login",
    emailLabel: "Email:",
    emailPlaceholder: "Please enter your email",
    passwordLabel: "Password:",
    passwordPlaceholder: "Please enter your password",
    registerLink: "/register",
    forgotLink: "/reset",
    registerText: "Register",
    forgot: "forget",
    clearButtonText: "Clear",
    loginButtonText: "Login",
    resetTitle: "Retrieve password",
    resetBtn: "ok",
  },
  register: {
    title: "Register",
    labelWidth: "74px",
    emailLabel: "Email:",
    emailPlaceholder: "Please enter your email",
    passwordLabel: "Password:",
    passwordPlaceholder: "Please enter your password",
    nicknameLabel: "Nickname:",
    nicknamePlaceholder: "Please enter your nickname",
    sexLabel: "Gender:",
    sexPlaceholder: "Please select gender",
    male: "Male",
    female: "Female",
    roleLabel: "Role:",
    rolePlaceholder: "Please select role",
    frontEnd: "Front-end",
    backEnd: "Back-end",
    cloudNative: "Cloud Native",
    clearButtonText: "Clear",
    registerButtonText: "Register",
    code: "Code:",
    pleaseInputCode: "please enter verification code",
    send: "Send",
  },
  publishedPage: {
    imageAlt: "Happy image",
    createT: "The title you created",
    successMessage:
      "Published successfully! With your sharing, { appName } will become better!",
    toIndex: "Article Management",
    inputTitle: "Please enter a title",
    subTitle: "Please enter a summary",
  },
  articleList: {
    author: "Author",
    unknownAuthor: "Unknown Author",
    like: "Like",
    edit: "Edit",
    delete: "Delete",
    noContent: "No content available, start writing!",
  },
  homeTabs: {
    latestArticles: "Latest Articles",
    someOtherTab: "Some Other Tab",
  },
  about: {
    title: "About Us",
  },
  button: {
    delete: "Delete",
    publish: "Publish",
    articleManagement: "Article Management",
  },
  label: {
    title: "Title",
    type: "Type",
    summary: "Summary",
    coverPicture: "Picture",
  },
  home: {
    title: "Home",
    welcome: "Welcome to our website!",
  },
};
