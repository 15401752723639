import { createI18n } from "vue-i18n";
import enUS from "./en-US";
import zhCN from "./zh-CN";

const i18n = createI18n({
  legacy: false, // Vue 3 组合式 API
  locale: "zh-CN", // 设置默认语言
  fallbackLocale: "en-US", // 设置备用语言
  messages: {
    "en-US": enUS,
    "zh-CN": zhCN,
  },
});

export default i18n;
