<template>
  {{ options.find((item) => item.code == props.type)?.chineseDesc }}
</template>

<script setup>
import { useStore } from "vuex";
import { computed, defineProps } from "vue";
const { state } = useStore();
const props = defineProps({
  type: {},
});

const options = computed(() => state.moduleArticle.articleOptions);
</script>

<style lang="scss" scoped></style>
