<template>
  <div class="header">
    <LogoCom />
    <div class="main-title">
      <h4 class="author-page-t">{{ t("header.homeTitle") }}</h4>
      <div class="btn-box">
        <ToCreateBtn />
        <el-button type="primary" @click="openAuthorCenter">{{
          t("header.articleManagement")
        }}</el-button>
        <el-button @click="switchLanguage">{{ lang }}</el-button>
        <User />
      </div>
    </div>
  </div>
  <div class="main">
    <router-view />
    <div class="footer">
      <span>Powered By Odin</span>
      <a href="https://github.com/XiaoMi/mone" target="_blank">
        <svg
          t="1675324234846"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2674"
          width="16"
          height="16"
        >
          <path
            d="M512 42.666667A464.64 464.64 0 0 0 42.666667 502.186667 460.373333 460.373333 0 0 0 363.52 938.666667c23.466667 4.266667 32-9.813333 32-22.186667v-78.08c-130.56 27.733333-158.293333-61.44-158.293333-61.44a122.026667 122.026667 0 0 0-52.053334-67.413333c-42.666667-28.16 3.413333-27.733333 3.413334-27.733334a98.56 98.56 0 0 1 71.68 47.36 101.12 101.12 0 0 0 136.533333 37.973334 99.413333 99.413333 0 0 1 29.866667-61.44c-104.106667-11.52-213.333333-50.773333-213.333334-226.986667a177.066667 177.066667 0 0 1 47.36-124.16 161.28 161.28 0 0 1 4.693334-121.173333s39.68-12.373333 128 46.933333a455.68 455.68 0 0 1 234.666666 0c89.6-59.306667 128-46.933333 128-46.933333a161.28 161.28 0 0 1 4.693334 121.173333A177.066667 177.066667 0 0 1 810.666667 477.866667c0 176.64-110.08 215.466667-213.333334 226.986666a106.666667 106.666667 0 0 1 32 85.333334v125.866666c0 14.933333 8.533333 26.88 32 22.186667A460.8 460.8 0 0 0 981.333333 502.186667 464.64 464.64 0 0 0 512 42.666667"
            fill="#231F20"
            p-id="2675"
          ></path>
        </svg>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import ToCreateBtn from "./ToCreateBtn.vue";
import LogoCom from "./LogoCom.vue";
import User from "./User.vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const lang = computed(() => {
  if (locale.value === "zh-CN") {
    return "中文";
  } else {
    return "english";
  }
});

const router = useRouter();
// 去文章管理菜单
const openAuthorCenter = () => {
  router.push({
    name: "ArticleManage",
  });
};
function switchLanguage() {
  // 切换语言
  if (locale.value === "zh-CN") {
    locale.value = "en-US";
  } else {
    locale.value = "zh-CN";
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  line-height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
}

.main-title {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-right: 23px;
}
.author-page-t {
  font-size: 17px;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;
  a {
    display: inline-block;
    margin-left: 8px;
  }
}
</style>
