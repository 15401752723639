import { Service } from "../service";
// import axios from "axios";

export function getList(params) {
  return Service({
    url: "/api/article/list",
    method: "post",
    params,
  });
}
export function createArticle(params) {
  return Service({
    url: "/api/article/create",
    method: "post",
    params,
  });
}
export function editArticle(params) {
  return Service({
    url: "/api/article/edit",
    method: "post",
    params,
  });
}
export function deleteArticle(params) {
  return Service({
    url: "/api/article/delete",
    method: "post",
    params,
  });
}
export function getArticle(params) {
  return Service({
    url: "/api/article/detail",
    method: "get",
    params,
  });
}
export function getArticleType(params) {
  return Service({
    url: "/api/article/type/enum",
    method: "get",
    params,
  });
}

export function uploadImage(params) {
  return Service({
    url: "/api/file/uploadPic",
    method: "post",
    params,
  });
}

export function getFiles(params) {
  return Service({
    url: "/api/file/files",
    method: "get",
    params,
  });
}

export function deleteFiles(params) {
  return Service({
    url: "/api/file/deleteFile",
    method: "get",
    params,
  });
}

// export function uploadImage(data) {
//   return axios({
//     url: "https://xmmionegw-outer-file.be.mi.com/mtop/file/file/upload",
//     method: "post",
//     data,
//   });
// }
