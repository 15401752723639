<template>
  <div class="back-white">
    <ArticleList
      :isManage="true"
      :listData="list"
      @refreshList="getListFn"
      :loading="loading"
    />
    <div class="page-box">
      <div></div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageInfo.pageSize"
        v-model:current-page="pageInfo.page"
        @current-change="changeCurPage"
      />
    </div>
  </div>
</template>

<script setup>
import ArticleList from "../ArticleList.vue";
import { onMounted, ref } from "vue";
import { getList } from "@/api/list/article.js";
const list = ref([]);
const total = ref(0);
const loading = ref(false);
const pageInfo = ref({
  page: 1,
  pageSize: 10,
});
const getListFn = () => {
  loading.value = true;
  const params = {
    page: 1,
    pageSize: 10,
    ...pageInfo.value,
  };
  getList(params)
    .then(({ data }) => {
      list.value = data.list || [];
      total.value = data.total;
    })
    .finally(() => {
      loading.value = false;
    });
};
const changeCurPage = () => {
  getListFn();
};
onMounted(() => {
  getListFn();
});
</script>

<style lang="scss" scoped>
.page-box {
  padding: 10px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
</style>
