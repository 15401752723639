<template>
  <div class="published-page">
    <div class="page-box width-1200">
      <!-- <img :src="imageSrc" :alt="$t('publishedPage.imageAlt')" /> -->
      <el-icon :size="48" color="#3de03d"><SuccessFilled /></el-icon>
      <h4 class="margin-10">{{ createT }}</h4>
      <p class="tips">
        {{ $t("publishedPage.successMessage", { appName: $appName }) }}
      </p>
      <el-button type="primary" plain class="btn-style" @click="toIndex">
        {{ $t("publishedPage.toIndex") }}
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";
const { state } = useStore();
const router = useRouter();
const toIndex = () => {
  router.push({
    name: "ArticleManage",
  });
};
const createT = computed(() => state.moduleArticle.publishedTiltle);
</script>

<style lang="scss" scoped>
.published-page {
  padding: 20px;
}
.page-box {
  background: #fff;
  text-align: center;
  padding: 50px 40px;
}
.tips {
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
  color: #4e5969;
}
.margin-10 {
  margin-top: 20px;
}
.btn-style {
  margin-top: 30px;
}
</style>
