<template>
  <el-menu
    :default-active="defaultActive"
    :router="true"
    class="el-menu-vertical-demo"
  >
    <el-menu-item v-for="item in menu" :index="item.index" :key="item.index">
      <div class="menu-item">
        <el-icon>
          <component :is="item.icon"></component>
        </el-icon>
        <span>{{ item.label }}</span>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const defaultActive = ref("/author/article-manage");
const menu = reactive([
  {
    label: t("header.articleManagement"),
    index: "/author/article-manage",
    icon: "Setting",
  },
  {
    label: t("header.authorCenter"),
    index: "/author/index",
    icon: "HomeFilled",
  },
]);
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo {
  border-right: none;
  text-align: left;
}
.menu-item {
  width: 120px;
}
</style>
