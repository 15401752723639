<template>
  <div class="top">
    <img src="../../assets/xiongmao.jpg" alt="" class="avatar" />
    <div class="detail">
      <p>{{ store.getters.userInfo.nickname || "游客" }}</p>
      <p>
        <span class="detail-info"
          >0
          <span class="gray-c">粉丝</span>
          <span class="border-span"></span>
        </span>
        <span class="detail-info"
          >0
          <span class="gray-c">关注</span>
          <span class="border-span"></span>
        </span>
      </p>
      <p class="btns">
        <el-button size="small" @click="handleEdit(1)">编辑</el-button
        ><el-button size="small" @click="handleEdit(2)">修改密码</el-button>
      </p>
    </div>
  </div>
  <div class="bottom">创作者中心-首页</div>
  <EditUser
    :visible="state.visible"
    :type="state.type"
    @onCancel="state.visible = false"
  />
</template>

<script setup>
import store from "@/store/index";
import { isLogin } from "@/utils/index.js";
import EditUser from "./EditUser.vue";
import { reactive } from "vue";
import { loginDialog } from "@/utils/dialog.js";

const state = reactive({
  visible: false,
  type: 1,
});

const handleEdit = (type) => {
  if (isLogin()) {
    state.type = type;
    state.visible = true;
  } else {
    loginDialog();
  }
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.detail {
  padding: 20px;
  p {
    text-align: left;
  }
}
.top,
.bottom {
  background: #fff;
  padding: 20px;
}
.border-span {
  display: inline-block;
  width: 0;
  height: 12px;
  border-right: solid 1px #8a919f;
  margin: 0 10px;
}
.detail-info:last-child {
  .border-span {
    display: none;
  }
}

.gray-c {
  color: #8a919f;
}
.btns {
  text-align: left;
  margin-top: 8px;
}
</style>
