import { getArticleType } from "@/api/list/article.js";
export default {
  namespaced: true,
  state: {
    publishedTiltle: "",
    articleOptions: [],
  },
  mutations: {
    SetPublishedTitle(state, data) {
      state.publishedTiltle = data;
    },
    setAtricleOptions(state, data) {
      state.articleOptions = data;
    },
  },
  actions: {
    getArtcileOptions({ commit }) {
      getArticleType({}).then(({ data }) => {
        commit("setAtricleOptions", data);
      });
    },
  },
};
