import { Service } from "../service";

export function addMessage(params) {
  return Service({
    url: "/api/message/addMessage",
    method: "post",
    params,
  });
}

export function getMessagesByArticleId(params) {
  return Service({
    url: "/api/message/getMessages",
    method: "get",
    params,
  });
}

export function updateLaud(params) {
  return Service({
    url: "/api/message/updateLaud",
    method: "get",
    params,
  });
}

export function deleteMessage(params) {
  return Service({
    url: "/api/message/deleteMessage",
    method: "get",
    params,
  });
}
