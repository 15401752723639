import { createStore } from "vuex";
import moduleArticle from "./article";
import moduleUser from "./user";

export default createStore({
  state: {},
  getters: {
    userInfo: (state) => state.moduleUser.userInfo,
  },
  mutations: {},
  actions: {},
  modules: {
    moduleArticle,
    moduleUser,
  },
});
