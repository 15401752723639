<template>
  <div class="logo-box pointer" @click="toIndex">
    <i class="iconfont icon-luntan"></i>
    <span class="title">{{ $appName }}</span>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const toIndex = () => {
  router.push({
    name: "HomePage",
  });
};
</script>

<style lang="scss" scoped>
.logo-box {
  line-height: 1;
  padding: 0 24px;
}
.icon-luntan {
  font-size: 22px;
  color: #409eff;
  font-weight: bolder;
  vertical-align: middle;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin-left: 5px;
  vertical-align: middle;
  display: inline-block;
  height: 24px;
  margin-top: -4px;
  color: #409eff;
}
</style>
