import { Service } from "../service";

// export function login(params) {
//   return Service({
//     url: "/api/user/login",
//     method: "post",
//     params,
//   });
// }

export function loginEn(params) {
  return Service({
    url: "/api/user/login-en",
    method: "post",
    params,
  });
}

export function logout() {
  return Service({
    url: "/api/user/logout",
    method: "post",
  });
}

export function register(params) {
  return Service({
    url: "/api/user/register",
    method: "post",
    params,
  });
}

export function getUserInfo(params) {
  return Service({
    url: "/api/user/info",
    method: "post",
    params,
  });
}

export function sendCode(params) {
  return Service({
    url: "/api/user/send-code",
    method: "get",
    params,
  });
}

export function editAvatar(params) {
  return Service({
    url: "/api/user/edit-avatar",
    method: "post",
    params,
  });
}

export function editPassword(params) {
  return Service({
    url: "/api/user/edit-password",
    method: "post",
    params,
  });
}

export function transformData(params) {
  return Service({
    url: "/api/user/transform-password",
    method: "get",
    params,
  });
}

export function restPassword(params) {
  return Service({
    url: "/api/user/reset-password",
    method: "post",
    params,
  });
}
