<template>
  <div class="home-box">
    <div class="width-1200">
      <el-tabs v-model="activeName" class="home-tabs back-white">
        <el-tab-pane :label="$t('homeTabs.latestArticles')" name="first">
          <ArticleList
            :is-manage="false"
            :list-data="list"
            :loading="loading"
          />
          <div class="page-box back-white">
            <div></div>
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="pageInfo.pageSize"
              v-model:current-page="pageInfo.page"
              @current-change="changeCurPage"
            />
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane :label="$t('homeTabs.someOtherTab')" name="second">dddd</el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import ArticleList from "@/components/ArticleList.vue";
import { onMounted, ref } from "vue";
import { getList } from "@/api/list/article.js";
const list = ref([]);
const total = ref(0);
const loading = ref(false);
const activeName = ref("first");
const pageInfo = ref({
  page: 1,
  pageSize: 10,
});
const getListFn = () => {
  loading.value = true;
  const params = {
    page: 1,
    pageSize: 10,
    ...pageInfo.value,
  };
  getList(params)
    .then(({ data }) => {
      list.value = data.list || [];
      total.value = data.total;
    })
    .finally(() => {
      loading.value = false;
    });
};
const changeCurPage = () => {
  getListFn();
};
onMounted(() => {
  getListFn();
});
</script>

<style lang="scss" scoped>
.page-box {
  padding: 10px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.home-box {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px 0;
}
.home-page {
  padding: 20px 0;
}
.home-tabs {
  :deep(.el-tabs__nav-scroll) {
    padding: 0 20px;
  }
  :deep(.list-box) {
    padding-top: 0;
  }
}
</style>
