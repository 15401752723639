<template>
  <div class="editor-wrap">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
      v-if="showToolbar"
    />
    <Editor
      :style="state.styles"
      v-model="valueHtml"
      :defaultConfig="{ ...defaultConf, ...editorConfig }"
      :mode="mode"
      @onCreated="handleCreated"
    />
  </div>
</template>

<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css

import {
  onBeforeUnmount,
  // ref,
  shallowRef,
  defineProps,
  defineEmits,
  computed,
  reactive,
} from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { ElMessage } from "element-plus";
import { uploadImage } from "@/api/list/article.js";

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
const mode = "default";
const props = defineProps({
  modelValue: {},
  showToolbar: {
    default: true,
  },
  editorConfig: {
    default: {
      placeholder: "请输入内容...",
    },
  },
  height: {
    type: String,
    default: "500px",
  },
});
const emits = defineEmits(["update:modelValue"]);
const state = reactive({
  styles: {
    "min-height": props.height,
    "overflow-y": "hidden",
  },
});
// 内容 HTML
const valueHtml = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emits("update:modelValue", v);
  },
});

const toolbarConfig = {};
// https://www.wangeditor.com/v5/menu-config.html#%E5%9F%BA%E6%9C%AC%E9%85%8D%E7%BD%AE
const defaultConf = {
  MENU_CONF: {
    uploadImage: {
      maxFileSize: 10 * 1024 * 1024, // 10M
      // 最多可上传几个文件，默认为 100
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: 1,
      customUpload(file, insertFn) {
        let param = new FormData();
        param.append("file", file, file.name);
        // param.append("fileName", file.name);
        // param.append("fileBizType", "default");
        // param.append("publicIdentify", 1);
        // param.append("networkIdentify", 1);
        // param.append("timestamp", new Date().getTime());
        // param.append("projectId", 780);
        uploadImage(param)
          .then((data) => {
            if (data.message === "ok") {
              let url =
                window.location.protocol +
                "//m.one.mi.com/image" +
                data.data.substr(data.data.lastIndexOf("/"));
              insertFn(url, file.name, url);
            } else {
              ElMessage.error(data.message);
            }
          })
          .catch(() => {});
      },
    },
    // uploadAttachment: {
    //   customBrowseAndUpload(res, insertFn) {
    //     insertFn(res.url, res.alt, res.href);
    //   },
    // },
  },
};

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};
</script>
<style lang="scss" scoped>
.editor-wrap {
  :deep(.w-e-toolbar) {
    padding: 0 5px;
    .w-e-bar-item {
      // border-bottom: 1px solid #e8e8e8;
    }
  }
}
</style>
