import { ElMessageBox } from "element-plus";

export const loginDialog = () => {
  ElMessageBox.confirm("去登录?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      window.location.href = window.location.origin + "/login";
    })
    .catch(() => {});
};
