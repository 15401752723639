export default {
  header: {
    defaultNickname: "游客",
    homeTitle: "首页",
    articleManagement: "文章管理",
    switchLanguage: "切换语言",
    createArticle: "写文章",
    authorCenter: "创作者中心",
  },
  login: {
    title: "登录",
    emailLabel: "邮箱:",
    emailPlaceholder: "请输入邮箱",
    passwordLabel: "密码:",
    passwordPlaceholder: "请输入密码",
    registerLink: "/register",
    forgotLink: "/reset",
    registerText: "去注册",
    forgot: "忘记密码",
    clearButtonText: "清空",
    loginButtonText: "登录",
    resetTitle: "重置密码",
    resetBtn: "确定",
  },
  register: {
    title: "注册",
    labelWidth: "74px",
    emailLabel: "邮箱:",
    emailPlaceholder: "请输入邮箱",
    passwordLabel: "密码:",
    passwordPlaceholder: "请输入密码",
    nicknameLabel: "昵称:",
    nicknamePlaceholder: "请输入昵称",
    sexLabel: "性别:",
    sexPlaceholder: "请选择性别",
    male: "男",
    female: "女",
    roleLabel: "角色:",
    rolePlaceholder: "请选择角色",
    frontEnd: "前端",
    backEnd: "后端",
    cloudNative: "云原生",
    clearButtonText: "清空",
    registerButtonText: "注册",
    code: "验证码:",
    pleaseInputCode: "请输入验证码",
    send: "发送",
  },
  publishedPage: {
    imageAlt: "快乐的图片",
    createT: "《你创建的标题》",
    successMessage: "发布成功！有了你的分享，{ appName }会变得更好！",
    toIndex: "文章管理",
    inputTitle: "请输入标题",
    subTitle: "请输入摘要",
  },
  articleList: {
    author: "作者",
    unknownAuthor: "作者未知",
    like: "点赞",
    edit: "编辑",
    delete: "删除",
    noContent: "快去写文章吧！",
  },
  homeTabs: {
    latestArticles: "最新文章",
    someOtherTab: "其他标签",
  },
  about: {
    title: "关于我们",
  },
  button: {
    delete: "删除",
    publish: "发布",
    articleManagement: "文章管理",
  },
  label: {
    title: "标题",
    type: "类型",
    summary: "摘要",
    coverPicture: "封面图",
  },
  home: {
    title: "首页",
    welcome: "欢迎来到我们的网站！",
  },
};
