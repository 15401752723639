<template>
  <div class="index-page">
    <template v-if="props.loading">
      <div class="skeleton-box">
        <el-skeleton :rows="2" animated />
      </div>
    </template>
    <template v-else>
      <ul class="list-box" v-if="listData?.length > 0">
        <li
          class="list-item pointer"
          v-for="item in props.listData"
          :key="item.id"
          @click="toArticle(item)"
        >
          <div class="main-content">
            <h3 class="title">
              <span class="content-span title-span">
                <div>{{ item.title }}</div>
              </span>
            </h3>
            <p class="content-color detail-content">
              <span class="content-span">
                <div>{{ item.summary }}</div>
              </span>
            </p>
            <p class="content-color">
              <template v-if="!props.isManage">
                <span class="detail-info"
                  >{{ $t("articleList.author")
                  }}{{ item.author || $t("articleList.unknownAuthor") }}</span
                >
                <span class="detail-info pointer">
                  <i class="iconfont icon-zan"></i> {{ $t("articleList.like") }}
                  <!-- {{ item.agree }} -->
                </span>
              </template>
              <template v-else>
                <span class="detail-info pointer" @click.stop="toDetail(item)">
                  {{ $t("articleList.edit") }}
                </span>
                <span class="detail-info pointer" @click.stop="deleteFn(item)">
                  {{ $t("articleList.delete") }}
                </span>
              </template>
            </p>
          </div>
          <img
            v-if="item.headImgUrl"
            class="item-img"
            :src="item.headImgUrl"
            alt=""
          />
          <img v-else class="item-img" src="../assets/happy.png" alt="" />
        </li>
      </ul>
      <div v-else class="back-white">
        <el-empty :description="$t('articleList.noContent')" />
      </div>
    </template>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { deleteArticle } from "@/api/list/article.js";
import { isLogin } from "@/utils/index.js";
import { loginDialog } from "@/utils/dialog.js";
const router = useRouter();
const props = defineProps({
  listData: {},
  isManage: {}, // 是否是管理页面
  loading: {
    default: false,
  },
});
const emits = defineEmits(["refreshList"]);
const toDetail = (item) => {
  if (isLogin()) {
    router.push({
      name: "AuthorCreate",
      query: {
        id: item.id,
      },
    });
  } else {
    loginDialog();
  }
};
const toArticle = (item) => {
  router.push({
    name: "ArticleDetailPage",
    params: {
      id: item.id,
    },
  });
};

const sendDel = (id) => {
  deleteArticle({ id })
    .then(() => {
      ElMessage({
        type: "success",
        message: "删除成功",
      });
      emits("refreshList");
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteFn = (item) => {
  if (isLogin()) {
    const { title } = item;
    const showTitle =
      item.title.length > 20 ? `${title.slice(0, 20)}...` : title;
    ElMessageBox.confirm(`您确定要删除《${showTitle}》?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        sendDel(item.id);
      })
      .catch(() => {});
  } else {
    loginDialog();
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  list-style: none;
  display: flex;
  padding: 12px 0;
  border-bottom: solid 1px rgba(228, 230, 235, 0.5);
  align-items: center;
  .main-content {
    flex: 1 1 auto;
    text-align: left;
  }
  .item-img {
    margin-left: 20px;
    width: 96px;
  }
}
.list-box {
  background: #fff;
  padding: 12px 20px;
}
.title {
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  color: #252933;
  width: 100%;
  display: -webkit-box;
  margin-bottom: 2px;
}
.content-color {
  color: #8a919f;
}
.detail-info {
  position: relative;
  max-width: 132px;
  font-size: 13px;
  margin-right: 20px;
}
.detail-info::after {
  content: "";
  width: 0;
  height: 12px;
  border-right: solid 1px #8a919f;
  position: absolute;
  right: -10px;
  top: 2px;
}
.detail-info:last-child::after {
  display: none;
}
.content-span {
  font-size: 13px;
  line-height: 22px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}
.title-span {
  font-size: 16px;
}
.detail-content {
  margin: 4px 0;
}
.icon-zan {
  font-weight: 600;
  font-size: 13px;
}
.skeleton-box {
  text-align: left;
  padding: 20px 20px 0 20px;
}
</style>
