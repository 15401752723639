<template>
  <div class="operate">
    <div class="time">
      {{ moment(props.item.ctime).startOf("second").fromNow() }}
    </div>
    <div class="like" @click="handleLaud">
      <svg
        t="1700642608302"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2374"
        width="16"
        height="16"
      >
        <path
          d="M832 364.8h-147.2s19.2-64 32-179.2c6.4-57.6-38.4-115.2-102.4-121.6h-12.8c-51.2 0-83.2 32-102.4 76.8l-38.4 96c-32 64-57.6 102.4-76.8 115.2-25.6 12.8-121.6 12.8-128 12.8H128c-38.4 0-64 25.6-64 57.6v480c0 32 25.6 57.6 64 57.6h646.4c96 0 121.6-64 134.4-153.6l51.2-307.2c6.4-70.4-6.4-134.4-128-134.4z m-576 537.6H128V422.4h128v480z m640-409.6l-51.2 307.2c-12.8 57.6-12.8 102.4-76.8 102.4H320V422.4c44.8 0 70.4-6.4 89.6-19.2 32-12.8 64-64 108.8-147.2 25.6-64 38.4-96 44.8-102.4 6.4-19.2 19.2-32 44.8-32h6.4c32 0 44.8 32 44.8 51.2-12.8 102.4-32 166.4-32 166.4l-25.6 83.2h243.2c19.2 0 32 0 44.8 12.8 12.8 12.8 6.4 38.4 6.4 57.6z"
          p-id="2375"
          fill="#8a919f"
        ></path></svg
      ><span>{{ props.item.laud || 0 }}</span>
    </div>
    <div class="msg-num" @click="handleEdit" v-if="props.layer < 6">
      <el-icon color="#8a919f" :size="16"><ChatDotSquare /></el-icon
      ><span>
        {{ props.item.list?.length ? props.item.list?.length : "回复" }}
      </span>
    </div>
    <div class="msg-num" @click="handleDele">
      <span> 删除 </span>
    </div>
  </div>
  <div v-if="state.isEdit">
    <el-input
      v-model.trim="state.msg"
      :rows="2"
      type="textarea"
      placeholder="请输入留言"
      :maxlength="300"
      show-word-limit
      style="width: 400px"
    />
    <div class="btns">
      <el-button size="small" @click="handleCancel">取消</el-button>
      <el-button
        :disabled="!state.msg"
        size="small"
        type="primary"
        @click="handleOk"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import { addMessage, updateLaud, deleteMessage } from "@/api/message/index";
import { defineProps, defineEmits } from "vue";
import { ElMessage } from "element-plus";
import { isLogin } from "@/utils/index.js";
import { loginDialog } from "@/utils/dialog.js";
import moment from "moment";
moment.locale("zh_CN");
const events = defineEmits(["getList"]);
const props = defineProps({
  articleId: {
    type: Number,
    default: undefined,
  },
  parentId: {
    type: Number,
    default: undefined,
  },
  layer: {
    type: Number,
    default: undefined,
  },
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});
const state = reactive({
  msg: undefined,
  isEdit: false,
});
const handleDele = () => {
  deleteMessage({
    id: props.item.id,
  })
    .then((data) => {
      if (data.message == "ok") {
        events("getList");
        ElMessage.success("删除成功！");
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
const handleEdit = () => {
  state.isEdit = true;
};
const handleCancel = () => {
  state.isEdit = false;
  state.msg = undefined;
};
const handleLaud = () => {
  updateLaud({
    id: props.item.id,
  })
    .then((data) => {
      if (data.message == "ok") {
        events("getList");
        ElMessage.success("点赞成功！");
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
const handleOk = () => {
  if (!isLogin()) {
    loginDialog();
    return;
  }
  addMessage({
    articleId: props.articleId,
    parentId: props.parentId,
    layer: props.parentId,
    message: state.msg,
  })
    .then((data) => {
      if (data.message == "ok") {
        events("getList");
        ElMessage.success("留言成功！");
        handleCancel();
      } else {
        ElMessage.error(data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #8a919f;
  font-size: 14px;
  .like,
  .msg-num {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    span {
      display: inline-block;
      margin-left: 4px;
    }
  }
  .like {
    margin: 0 12px;
  }
}
.btns {
  margin-top: 8px;
}
</style>
