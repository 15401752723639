/* eslint-disable no-param-reassign */
import axios from "axios";
import { ElMessage } from "element-plus";

function msgFn(msg) {
  ElMessage({
    message: msg,
    type: "error",
    duration: 3 * 1000,
  });
}

// 使用create方法创建axios实例
export const Service = axios.create({
  timeout: 30000, // 请求超时时间
  method: "post",
});

Service.interceptors.request.use(
  (config) => {
    if (config.method === "post" || config.method === "put") {
      config.data = config.params;
      config.params = {};
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// 添加响应拦截器
Service.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data.code !== 0) {
      msgFn(data.message || "出错了");
      return Promise.reject();
    }
    return data;
  },
  (error) => {
    let msg = "";
    if (error.message.includes("timeout")) {
      msg = error.config.timeoutMsg || "请求超时";
    } else {
      msg = error.response?.data?.message || error.Message || "出错了！";
    }
    msgFn(msg);
    return Promise.reject(error);
  }
);
